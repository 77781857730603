import { EfficiencyChart } from './EfficiencyChart';
import { EfficiencyTable } from './EfficiencyTable';

export function EfficiencyDetails(): JSX.Element {
  return (
    <>
      <EfficiencyTable />
      <EfficiencyChart height={600} sx={{ marginX: 1, marginY: 4 }} />
    </>
  );
}
