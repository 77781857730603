import { DataSetRequestParams } from '../entities/DataSet';
import { EfficiencyHistoryRecord, EfficiencyHistoryRecordSchema } from '../entities/EfficiencyHistoryRecord';
import { api } from './baseApi';

const url = 'v2/efficiency';

const efficiencyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEfficiencyHistory: builder.query<EfficiencyHistoryRecord[], DataSetRequestParams>({
      query: (params) => ({ url: `${url}/history`, params }),
      transformResponse: (data) => EfficiencyHistoryRecordSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadEfficiencyHistoryQuery } = efficiencyApi;
