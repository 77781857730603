import { DataSetRequestParams } from '../entities/DataSet';
import { ProductivityDetailsGroup, ProductivityDetailsGroupSchema } from '../entities/ProductivityDetails';
import { api } from './baseApi';

const url = 'v2/productivities';

const productivityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readProductivityDetails: builder.query<ProductivityDetailsGroup[], DataSetRequestParams>({
      query: (params) => ({ url: `${url}/detail`, params }),
      transformResponse: (data) => ProductivityDetailsGroupSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadProductivityDetailsQuery } = productivityApi;
