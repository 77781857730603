import { Link, Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import { NotFoundBox } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { DataSetFilters, DateRangeFilters } from '../../components/HeaderFilters';
import { Layout } from '../../components/Layout';
import { useDataSet } from '../../hooks/useDataSet';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { DetailsHeader } from './DetailsHeader';
import { EfficiencyDetails } from './EfficiencyDetails';
import { ProductivityDetails } from './ProductivityDetails';

export function DetailsPage() {
  const { data, error, isFetching } = useDataSet();
  const location = useLocation();
  const match = useMatch('details/:tab');

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        title={data?.name}
        error={error}
        inProgress={isFetching}
        maxWidth={error || !data ? 'sm' : undefined}
        headerFilters={
          <>
            <DateRangeFilters />
            <DataSetFilters />
          </>
        }
      >
        {data ? (
          <>
            <DetailsHeader />
            <Paper sx={{ marginY: 1 }}>
              {data.value.productivity && (
                <Tabs value={match?.params.tab} centered sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tab
                    value="efficiency"
                    label="Efficiency"
                    component={Link}
                    to={{ pathname: './efficiency', search: location.search }}
                    sx={{ flex: '1  0 0%' }}
                  />
                  <Tab
                    value="productivity"
                    label="Productivity_OE"
                    component={Link}
                    to={{ pathname: './productivity', search: location.search }}
                    sx={{ flex: '1  0 0%' }}
                  />
                </Tabs>
              )}
              <Routes>
                <Route path="efficiency" element={<EfficiencyDetails />} />
                <Route path="productivity" element={<ProductivityDetails />} />
                <Route path="*" element={<Navigate to={{ pathname: './efficiency', search: location.search }} />} />
              </Routes>
            </Paper>
          </>
        ) : (
          <NotFoundBox />
        )}
      </Layout>
    </AuthGuard>
  );
}
