import { useMemo } from 'react';
import { DataGrid } from '@top-solution/microtecnica-mui';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useProductivityDetails } from '../../hooks/useProductivityDetails';
import { formatNumber } from '../../utils/valueHelpers';

type ProductivityDataGrid = {
  id: string;
  group: string;
  item?: string;
  description?: string;
  hours: number;
};

const columns: GridColDef[] = [
  {
    field: '__tree_data_group__',
    width: 250,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    field: 'hours',
    headerName: 'Hours',
    align: 'right',
    valueFormatter: ({ value }) => formatNumber(value, 2),
  },
];

export function ProductivityDataGrid(): JSX.Element {
  const { data } = useProductivityDetails();

  const rows = useMemo(() => {
    const rows: ProductivityDataGrid[] = [];
    if (data?.length) {
      data.forEach((group) => {
        rows.push({
          id: group.id,
          group: group.id,
          hours: group.hours,
        });
        group.items.forEach((item) => {
          rows.push({
            id: `${group.id}:${item.id}`,
            group: group.id,
            item: item.id,
            description: item.name,
            hours: item.hours,
          });
        });
      });
    }
    return rows;
  }, [data]);

  return (
    <DataGrid
      treeData
      columns={columns}
      rows={rows}
      getTreeDataPath={(row) => row.id.split(':')}
      defaultGroupingExpansionDepth={0}
      density="compact"
      initialState={{
        sorting: {
          sortModel: [{ field: 'hours', sort: 'desc' }],
        },
      }}
      disableColumnMenu
      hideFooter
      autoHeight
    />
  );
}
