import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CommonDataApiProvider, setAuthApiUrl, ZodLocalizationProvider } from '@top-solution/microtecnica-utils';
import {
  BarElement,
  BarController,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import it from 'date-fns/locale/it';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/noto-serif/400-italic.css';
import './index.css';
import { App } from './App';
import { store } from './store';
import { theme } from './theme';

const appId = `${import.meta.env.VITE_APP_ID}`;
const appVersion = `${import.meta.env.VITE_VERSION}`;
const authApi = `${import.meta.env.VITE_AUTH_API}`;

// eslint-disable-next-line
console.log(`🚀 ${appId} (v. ${appVersion}) 🚀`);

setAuthApiUrl(`${authApi}/api`);

LicenseInfo.setLicenseKey(
  'da8085b1e1c9177df9b518a058262b48Tz02NTk3OSxFPTE3MTUxNzUyOTY5ODYsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <StrictMode>
    <CommonDataApiProvider value={{ appId, authApi }}>
      <ZodLocalizationProvider />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <BrowserRouter>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </CommonDataApiProvider>
  </StrictMode>
);
