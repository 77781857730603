import { NotFoundBox } from '@top-solution/microtecnica-mui';
import { Layout } from '../../components/Layout';

export function NotFoundPage(): JSX.Element {
  return (
    <Layout title="Pagina non trovata" maxWidth="sm">
      <NotFoundBox />
    </Layout>
  );
}
