import { ReactNode, useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Fraction } from '../../components/Fraction';
import { useDataSet } from '../../hooks/useDataSet';
import { formatDate } from '../../utils/date';
import { formatPercentage, dataSetStatus } from '../../utils/valueHelpers';
import { DownloadDetailsButton } from './DownloadDetailsButton';

type DetailsRow = {
  parameter: ReactNode;
  code: string;
  description: ReactNode;
  entity: ReactNode;
  value: number | null;
  target: number;
  date: Date | null;
  freq: string;
};

export function EfficiencyTable(): JSX.Element {
  const { data, params, lastADPUpdate, lastUpdate } = useDataSet();

  const rows = useMemo<DetailsRow[]>(() => {
    const rows: DetailsRow[] = [];
    if (data) {
      if (data.value.uora) {
        rows.push({
          parameter: (
            <>
              Utilization of Resource <Box sx={{ fontSize: '.9em', display: 'block' }}>(absolute data)</Box>
            </>
          ),
          code: 'UoR_a',
          description: <Fraction numerator="PrH (ProductiveHours)" denominator="PH (PresenceHours)" decimals={2} />,
          entity: <Fraction numerator={data.value.uora.prh} denominator={data.value.uora.ph} decimals={2} />,
          value: data.value.uora.ph !== 0 ? data.value.uora.value : null,
          target: data.value.uora.target,
          date: lastADPUpdate,
          freq: 'weekly',
        });
      }
      rows.push(
        {
          parameter: 'Efficiency_r',
          code: 'E_r',
          description: <Fraction numerator="EH (EarnedHours)" denominator="DLH (DirectLaborHours)" decimals={2} />,
          entity: (
            <Fraction numerator={data.value.efficiency.eh} denominator={data.value.efficiency.dlh} decimals={2} />
          ),
          value: data.value.efficiency.dlh !== 0 ? data.value.efficiency.value : null,
          target: data.value.efficiency.target,
          date: lastUpdate,
          freq: 'daily',
        },
        {
          parameter: 'Efficiency_a',
          code: 'E_a',
          description: <Fraction numerator="EH_a (EarnedHours)" denominator="DLH_a (DirectLaborHours)" decimals={2} />,
          entity: (
            <Fraction numerator={data.value.efficiency_a.eh} denominator={data.value.efficiency_a.dlh} decimals={2} />
          ),
          value: data.value.efficiency_a.dlh !== 0 ? data.value.efficiency_a.value : null,
          target: data.value.efficiency_a.target,
          date: lastUpdate,
          freq: 'daily',
        },
        {
          parameter: 'Efficiency_co',
          code: 'E_co',
          description: (
            <Fraction numerator="EH_co (EarnedHours)" denominator="DLH_co (DirectLaborHours)" decimals={2} />
          ),
          entity: (
            <Fraction numerator={data.value.efficiency_co.eh} denominator={data.value.efficiency_co.dlh} decimals={2} />
          ),
          value: data.value.efficiency_co.dlh !== 0 ? data.value.efficiency_co.value : null,
          target: data.value.efficiency_co.target,
          date: lastUpdate,
          freq: 'daily',
        }
      );
      if (data.value.productivity) {
        rows.push({
          parameter: 'Productivity_OE',
          code: 'P_OE',
          description: <span className="formulae">UoR_A &times; E</span>,
          entity: (
            <span className="formulae">
              {formatPercentage(data.value.productivity.uora)} &times; {formatPercentage(data.value.productivity.e)}
            </span>
          ),
          value: data.value.productivity.uora !== 0 ? data.value.productivity.value : null,
          target: data.value.productivity.target,
          date: lastADPUpdate,
          freq: 'weekly',
        });
      }
    }
    return rows;
  }, [data, lastADPUpdate, lastUpdate]);

  return (
    <TableContainer>
      <Table
        sx={{
          '.value': {
            textAlign: 'right',
            fontWeight: 'medium',
            fontSize: '1.1em',
            '&.success': { color: 'success.main' },
            '&.error': { color: 'error.main' },
            '&.warning': { color: 'warning.main' },
            '&.target': { color: 'info.main' },
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell>
            <TableCell align="center">Code</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Calculation Entity</TableCell>
            <TableCell align="center">Actual Value</TableCell>
            <TableCell align="center">Target</TableCell>
            <TableCell align="center">Last Update</TableCell>
            <TableCell align="center">Update Freq.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.parameter}
              </TableCell>
              <TableCell>
                <span className="formulae">{row.code}</span>
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.entity}</TableCell>
              <TableCell
                className={`value ${row.value !== null ? dataSetStatus({ value: row.value, target: row.target }) : ''}`}
              >
                {row.value !== null ? formatPercentage(row.value) : '–'}
              </TableCell>
              <TableCell className="value target">{row.target && formatPercentage(row.target)}</TableCell>
              <TableCell>{row.date && formatDate(row.date, 'P')}</TableCell>
              <TableCell>{row.freq}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {params?.endDate && (
        <Grid container spacing={1} justifyContent="flex-end" padding={1}>
          <Grid item>
            <DownloadDetailsButton baseUrl="api/v2/efficiency/raw" fileNamePrefix="Efficiency details" params={params}>
              Download Efficiency details
            </DownloadDetailsButton>
          </Grid>
          <Grid item>
            <DownloadDetailsButton
              baseUrl="api/v2/efficiency/raw-a"
              fileNamePrefix="Efficiency_a details"
              params={params}
            >
              Download Efficiency_a details
            </DownloadDetailsButton>
          </Grid>
          <Grid item>
            <DownloadDetailsButton
              baseUrl="api/v2/efficiency/raw-co"
              fileNamePrefix="Efficiency_co details"
              params={params}
            >
              Download Efficiency_co details
            </DownloadDetailsButton>
          </Grid>
          <Grid item>
            <DownloadDetailsButton
              baseUrl="api/v2/efficiency/unconfirmed-operations"
              fileNamePrefix="Unconfirmed Operations details"
              params={params}
            >
              Download Not confirmed operations details
            </DownloadDetailsButton>
          </Grid>
          {data?.value.uora && (
            <Grid item>
              <DownloadDetailsButton baseUrl="api/v2/utilizations" fileNamePrefix="UoR_A details" params={params}>
                Download UoR_A details
              </DownloadDetailsButton>
            </Grid>
          )}
        </Grid>
      )}
    </TableContainer>
  );
}
