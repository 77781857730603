import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function FullPageProgress(props: BoxProps): JSX.Element {
  const { sx, ...boxProps } = props;
  return (
    <Box
      sx={{
        minHeight: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...boxProps}
    >
      <CircularProgress />
    </Box>
  );
}

export default FullPageProgress;
