import { z } from 'zod';

export const DataSetSchema = z.object({
  value: z.number(),
  target: z.number(),
});

export type DataSet = z.infer<typeof DataSetSchema>;

export const EfficiencyDataSetSchema = DataSetSchema.extend({
  dlh: z.number(),
  dlh_adp: z.number(),
  eh: z.number(),
  eh_adp: z.number(),
});

export type EfficiencyDataSet = z.infer<typeof EfficiencyDataSetSchema>;

export const ProductivityDataSetSchema = DataSetSchema.extend({
  e: z.number(),
  uora: z.number(),
});

export type ProductivityDataSet = z.infer<typeof ProductivityDataSetSchema>;

export const UtilizationOfResourceDataSetSchema = DataSetSchema.extend({
  ph: z.number(),
  prh: z.number(),
  prh_employee: z.number(),
  prh_machine: z.number(),
});

export type UtilizationOfResourceDataSet = z.infer<typeof UtilizationOfResourceDataSetSchema>;
import { formatISODate } from '../utils/date';

export type DataSetParams = {
  plant?: string;
  cell?: string;
  workcenter?: string;
  startDate?: Date;
  endDate?: Date;
};

export type DataSetRequestParams = {
  plant?: string;
  cell?: string;
  startDate: string;
  endDate: string;
};

export function createDataSetRequestParams({ startDate, endDate, ...params }: DataSetParams): DataSetRequestParams {
  if (!startDate) {
    throw new Error('Missing required params "startDate"');
  }
  if (!endDate) {
    throw new Error('Missing required params "endDate"');
  }
  return {
    ...params,
    startDate: formatISODate(startDate),
    endDate: formatISODate(endDate),
  };
}
