import { useCallback, useMemo } from 'react';
import { useSearchParams as useRouterSearchParams } from 'react-router-dom';
import { endOfDay, startOfMonth } from 'date-fns';
import { DataSetParams } from '../entities/DataSet';
import { formatISODate, parseISODate } from '../utils/date';
import { useAvailableDates } from './useAvailableDates';

type UseSearchParams = [
  DataSetParams,
  (searchParams: DataSetParams) => void,
  {
    minDate: Date | undefined;
    maxDate: Date | undefined;
  }
];

export function useSearchParams(): UseSearchParams {
  const [qs, setQs] = useRouterSearchParams();
  const availableDates = useAvailableDates();

  const minDate = useMemo(() => availableDates.firstAvailableDate ?? undefined, [availableDates.firstAvailableDate]);

  const maxDate = endOfDay(new Date());

  const params = useMemo<DataSetParams>(
    () => ({
      plant: qs.get('plant') ?? undefined,
      cell: qs.get('cell') ?? undefined,
      workcenter: qs.get('workcenter') ?? undefined,
      startDate: parseISODate(qs.get('startDate')) ?? (maxDate && startOfMonth(maxDate)),
      endDate: parseISODate(qs.get('endDate')) ?? maxDate,
    }),
    [maxDate, qs]
  );

  const handleParamsChange = useCallback(
    (params: DataSetParams) => {
      const qsParams = new URLSearchParams();
      if (params.startDate) {
        qsParams.set('startDate', formatISODate(params.startDate));
      }
      if (params.endDate) {
        qsParams.set('endDate', formatISODate(params.endDate));
      }
      if (params.plant) {
        qsParams.set('plant', params.plant);
      }
      if (params.cell) {
        qsParams.set('cell', params.cell);
      }
      if (params.workcenter) {
        qsParams.set('workcenter', params.workcenter);
      }
      setQs(qsParams);
    },
    [setQs]
  );

  return [params, handleParamsChange, { minDate, maxDate }];
}
