import { ParserRun, ParserRunSchema } from '../entities/ParserRun';
import { api, TAG_TYPES } from './baseApi';

const url = 'v2/parse';

const parserApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readParserRunList: builder.query<ParserRun[], void>({
      query: () => ({ url }),
      transformResponse: (data) => ParserRunSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.PARSER, id: 'LIST' }],
    }),
    startParserRun: builder.mutation<void, void>({
      query: () => ({ url, method: 'POST' }),
      invalidatesTags: () => [{ type: TAG_TYPES.PARSER, id: 'LIST' }],
    }),
    // checkStatus: builder.query<ParserRun, void>({
    //   query: () => ({ url: `${url}/busy` }),
    //   transformResponse: (data) => ParserRunSchema.parse(data),
    // }),
  }),
  overrideExisting: false,
});

export const { useReadParserRunListQuery, useStartParserRunMutation } = parserApi;
