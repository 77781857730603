import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { formatNumber } from '../utils/valueHelpers';

export type FractionProps = BoxProps & {
  numerator: number | ReactNode;
  denominator: number | ReactNode;
  decimals?: number;
};

export function Fraction(props: FractionProps): JSX.Element {
  const { numerator, denominator, decimals, sx, ...boxProps } = props;
  return (
    <Box
      className="Fraction-root formulae"
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        hr: {
          width: 1 / 1,
          marginY: 0.5,
          borderStyle: 'solid',
          borderBottom: 0,
        },
        ...sx,
      }}
      {...boxProps}
    >
      <span>{typeof numerator === 'number' ? formatNumber(numerator, decimals) : numerator}</span>
      <hr />
      <span>{typeof denominator === 'number' ? formatNumber(denominator, decimals) : denominator}</span>
    </Box>
  );
}
