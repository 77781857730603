import { DataSetRequestParams } from '../entities/DataSet';
import { Overview, OverviewSchema } from '../entities/Overview';
import { api } from './baseApi';

const url = 'v2/overview';

const overviewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readOverview: builder.query<Overview, DataSetRequestParams>({
      query: (params) => ({ url, params }),
      transformResponse: (data) => OverviewSchema.parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadOverviewQuery } = overviewApi;
