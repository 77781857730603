import { useMemo } from 'react';
import { ChartConfiguration } from 'chart.js';
import { BoxProps } from '@mui/material/Box';
import { useTheme, alpha } from '@mui/material/styles';
import { Chart } from '../../components/Chart';
import { DataSetParams } from '../../entities/DataSet';
import { ProductivityDetailsItem } from '../../entities/ProductivityDetails';
import { useProductivityDetails } from '../../hooks/useProductivityDetails';
import { formatDate } from '../../utils/date';

const chartConfiguration: ChartConfiguration<'bar'> = {
  type: 'bar',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Hours',
        data: [],
        borderWidth: 1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    layout: {
      padding: 8,
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        align: 'start',
        text: 'Lost hours',
        font: {
          size: 20,
          weight: 'normal',
        },
        padding: {
          bottom: 20,
        },
      },
      legend: {
        position: 'top',
      },
      tooltip: {
        intersect: false,
      },
    },
  },
};

export type ProductivityChartProps = BoxProps & DataSetParams;

export function ProductivityChart(props: ProductivityChartProps): JSX.Element {
  const { ...boxProps } = props;
  const { palette } = useTheme();
  const {
    data,
    params: { startDate, endDate, plant, cell, workcenter },
    isFetching,
    error,
  } = useProductivityDetails();

  const config = useMemo<ChartConfiguration<'bar'>>(() => {
    if (data) {
      const lostHours = data
        .filter((group) => group.id !== 'N/A')
        .reduce((list, group) => list.concat(group.items), [] as ProductivityDetailsItem[])
        .filter((item) => item.hours > 0)
        .sort((a, b) => b.hours - a.hours);

      return {
        ...chartConfiguration,
        data: {
          ...chartConfiguration.data,
          labels: lostHours.map(({ name }) => name),
          datasets: [
            {
              ...chartConfiguration.data.datasets[0],
              data: lostHours.map(({ hours }) => hours),
              borderColor: palette.error.dark,
              backgroundColor: alpha(palette.error.dark, 0.2),
            },
          ],
        },
        options: {
          ...chartConfiguration.options,
          plugins: {
            ...chartConfiguration.options?.plugins,
            title: {
              ...chartConfiguration.options?.plugins?.title,
              text: `${chartConfiguration.options?.plugins?.title?.text}${
                workcenter ? ' workcenter' : cell ? ' cell' : ''
              } ${workcenter ?? cell ?? plant ?? 'Italy'}${
                startDate && endDate ? ` ${formatDate(startDate, 'P')}-${formatDate(endDate, 'P')}` : ''
              }`,
            },
          },
        },
      };
    }
    return chartConfiguration;
  }, [cell, data, endDate, palette.error.dark, plant, startDate, workcenter]);

  return <Chart config={config} loading={isFetching} error={error} {...boxProps} />;
}
