import { useMemo } from 'react';
import { useReadAvailableDatesQuery } from '../services/availableDatesApi';
import { parseISODate } from '../utils/date';

type UseAvailableDates = Omit<ReturnType<typeof useReadAvailableDatesQuery>, 'data'> & {
  firstAvailableDate: Date | null;
  lastAvailableADPDate: Date | null;
  lastAvailableDate: Date | null;
};

export function useAvailableDates(): UseAvailableDates {
  const { data: response, ...status } = useReadAvailableDatesQuery();

  const data = useMemo(
    () => ({
      firstAvailableDate: parseISODate(response?.firstAvailableDate),
      lastAvailableADPDate: parseISODate(response?.lastAvailableADPDate),
      lastAvailableDate: parseISODate(response?.lastAvailableDate),
    }),
    [response]
  );

  return {
    ...status,
    ...data,
  };
}
