import { useMemo } from 'react';
import { Breadcrumb, DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { Layout } from '../../components/Layout';
import { ParserRun } from '../../entities/ParserRun';
import { UserRole } from '../../entities/User';
import { useReadParserRunListQuery, useStartParserRunMutation } from '../../services/parserApi';
import { formatDate } from '../../utils/date';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';

const breadcrumbs: Breadcrumb[] = [{ title: 'Parser status' }];

const dateColumn = {
  type: 'date',
  valueGetter: ({ row, field }: GridValueGetterParams<ParserRun>) => (row[field] ? new Date(row[field]) : undefined),
  valueFormatter: ({ value }: GridValueFormatterParams<Date>) => (value ? formatDate(value, 'Pp') : undefined),
  width: 150,
};

export function DebugPage(): JSX.Element {
  const parserRunList = useReadParserRunListQuery();
  const [run, runStatus] = useStartParserRunMutation();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'description',
        headerName: 'Description',
        width: 200,
        renderHeader: () => (
          <LoadingButton
            color="secondary"
            variant="contained"
            onClick={() => run()}
            loading={runStatus.isLoading}
            fullWidth
          >
            Run parser
          </LoadingButton>
        ),
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
      },
      {
        ...dateColumn,
        field: 'lastRun',
        headerName: 'Last Run',
      },
      {
        ...dateColumn,
        field: 'lastSuccess',
        headerName: 'Last Success',
      },
      { field: 'status', headerName: 'Status', width: 120 },
      { field: 'error', headerName: 'Error', width: 300 },
      { field: 'path', headerName: 'Path', width: 500 },
    ],
    [run, runStatus.isLoading]
  );

  return (
    <AuthGuard authorizeRole={(role) => role === UserRole.ADMIN} unauthorizedFallback={<UnauthorizedPage />}>
      <Layout title="Debug" maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <DataGridWrapper>
          <DataGrid
            getRowId={(row: ParserRun) => row.description}
            columns={columns}
            rows={parserRunList.data ?? []}
            loading={parserRunList.isFetching || runStatus.isLoading}
            error={parserRunList.error || runStatus.error}
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
