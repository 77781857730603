import { z } from 'zod';

const DataSchema = z.object({
  month: z.number(),
  efficiency: z.number().optional(),
  productivity: z.number().optional(),
  utilizationOfResource: z.number().optional(),
  dlh: z.number().optional(),
  eh: z.number().optional(),
  ph: z.number().optional(),
  prh: z.number().optional(),
});

const ReportItemSchema = z.object({
  name: z.string(),
  target: DataSchema.pick({ efficiency: true, productivity: true, utilizationOfResource: true }),
  data: DataSchema.array(),
});

export type ReportItem = z.infer<typeof ReportItemSchema>;

export const ReportSchema = ReportItemSchema.extend({
  plants: ReportItemSchema.extend({
    cells: ReportItemSchema.array(),
  }).array(),
});

export type Report = z.infer<typeof ReportSchema>;
