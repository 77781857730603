import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { DebugPage } from './pages/Debug';
import { DetailsPage } from './pages/Details';
import { NotFoundPage } from './pages/Error/NotFoundPage';
import { UnauthorizedPage } from './pages/Error/UnauthorizedPage';
import { OverviewPage } from './pages/Overview/OverviewPage';
import { ReportPage } from './pages/Report/ReportPage';
import { UsersPage } from './pages/Users';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<OverviewPage />} />
      <Route path="details/*" element={<DetailsPage />} />
      <Route path="report/*" element={<ReportPage />} />
      <Route path="debug" element={<DebugPage />} />
      <Route path="users" element={<UsersPage />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
