import { useCallback, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro';
import { useAvailableDates } from '../../hooks/useAvailableDates';
import { useSearchParams } from '../../hooks/useSearchParams';

export function DateRangeFilters(): JSX.Element {
  const availableDates = useAvailableDates();
  const [{ startDate, endDate, plant, cell, workcenter }, setSearchParams, { minDate, maxDate }] = useSearchParams();
  const dateRange = useMemo<DateRange<Date>>(() => [startDate ?? null, endDate ?? null], [endDate, startDate]);

  const handleDateRangeChange = useCallback(
    (range: DateRange<Date>) => {
      if (range[0] && range[1]) {
        setSearchParams({
          plant,
          cell,
          workcenter,
          startDate: range[0],
          endDate: range[1],
        });
      }
    },
    [cell, plant, setSearchParams, workcenter]
  );

  return availableDates.isFetching ? (
    <>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
    </>
  ) : (
    <DateRangePicker
      value={dateRange}
      minDate={minDate}
      maxDate={maxDate}
      onChange={handleDateRangeChange}
      renderInput={(startProps, endProps) => (
        <>
          <TextField className="DatePicker-root" size="small" {...startProps} />
          <TextField className="DatePicker-root" size="small" {...endProps} />
        </>
      )}
    />
  );
}
