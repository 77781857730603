import { useEffect, useRef } from 'react';
import { Gauge, GaugeOptions } from 'gaugeJS';
import Box, { BoxProps } from '@mui/material/Box';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataSet } from '../entities/DataSet';
import { formatPercentage } from '../utils/valueHelpers';

const defaultOptions: GaugeOptions = {
  lineWidth: 0.25,
  pointer: {
    length: 0.55,
  },
  renderTicks: {
    subDivisions: 5,
    divLength: 0.5,
  },
  staticLabels: {
    font: '10px sans-serif',
    labels: [],
  },
};

export type GaugeChartProps = BoxProps & {
  value: DataSet;
  max: number;
  title: string;
  width?: CircularProgressProps['size'];
};

export function GaugeChart(props: GaugeChartProps): JSX.Element {
  const { palette } = useTheme();
  const { title, width, sx, ...boxProps } = props;
  const gaugeRef = useRef<HTMLCanvasElement | null>(null);
  const w = width ?? 300;

  useEffect(() => {
    if (gaugeRef.current) {
      const value = props.value.value * 100;
      const target = props.value.target * 100;
      const max = props.max * 100;
      const gauge = new Gauge(gaugeRef.current);
      gauge.setOptions({
        ...defaultOptions,
        renderTicks: {
          ...defaultOptions.renderTicks,
          divisions: max / 50,
        },
        staticLabels: {
          ...defaultOptions.staticLabels,
          labels: [...new Array(Math.ceil(max / 50) + 1)].map((_, i) => i * 50),
        },
        staticZones: [
          { strokeStyle: palette.error.main, min: 0, max: target * 0.95 },
          { strokeStyle: palette.warning.light, min: target * 0.95, max: target },
          { strokeStyle: palette.success.main, min: target, max: max },
        ],
      });
      gauge.maxValue = max;
      gauge.set(value);
    }
  }, [palette, props.max, props.value.target, props.value.value]);

  return (
    <Box
      sx={{
        width: w,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        canvas: {
          width: w,
        },
        ...sx,
      }}
      {...boxProps}
    >
      <canvas ref={gaugeRef} />
      <Typography marginTop={1}>
        {title}: {formatPercentage(props.value.value)}
      </Typography>
    </Box>
  );
}
