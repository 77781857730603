import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ReactComponent as AccountCog } from '@mdi/svg/svg/account-cog.svg';
import { ReactComponent as ChartAreaspline } from '@mdi/svg/svg/chart-areaspline.svg';
import { ReactComponent as FileDownload } from '@mdi/svg/svg/file-download.svg';
import { ReactComponent as ListStatus } from '@mdi/svg/svg/list-status.svg';

export const createIcon = (icon: React.ReactNode) =>
  function Icon(props: SvgIconProps): JSX.Element {
    return <SvgIcon {...props}>{icon}</SvgIcon>;
  };

export const ChartAreasplineIcon = createIcon(<ChartAreaspline />);
export const ListStatusIcon = createIcon(<ListStatus />);
export const FileDownloadIcon = createIcon(<FileDownload />);
export const ManageUsersIcon = createIcon(<AccountCog />);
