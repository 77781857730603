import { AvailableDates, AvailableDatesSchema } from '../entities/AvailableDate';
import { api } from './baseApi';

const url = 'v2/date';

const availableDatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readAvailableDates: builder.query<AvailableDates, void>({
      query: () => ({ url }),
      transformResponse: (data) => AvailableDatesSchema.parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadAvailableDatesQuery } = availableDatesApi;
