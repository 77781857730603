import { useMemo } from 'react';
import { ChartConfiguration } from 'chart.js';
import { BoxProps } from '@mui/material/Box';
import { useTheme, alpha } from '@mui/material/styles';
import { Chart } from '../../components/Chart';
import { createDataSetRequestParams, DataSetParams } from '../../entities/DataSet';
import { useDataSet } from '../../hooks/useDataSet';
import { useReadEfficiencyHistoryQuery } from '../../services/efficiencyApi';
import { formatDate } from '../../utils/date';
import { formatPercentage } from '../../utils/valueHelpers';

const chartConfiguration: ChartConfiguration<'line'> = {
  type: 'line',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Cumulative',
        data: [],
        fill: true,
      },
      {
        label: 'Target',
        data: [],
        borderColor: 'rgba(0, 123, 255, 1)',
        fill: false,
        borderDash: [5, 5],
        pointHitRadius: 0,
        pointBorderWidth: 0,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    layout: {
      padding: 8,
    },
    plugins: {
      title: {
        display: true,
        align: 'start',
        text: 'Efficiency',
        font: {
          size: 20,
          weight: 'normal',
        },
        padding: {
          bottom: 20,
        },
      },
      legend: {
        position: 'top',
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatPercentage(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => (typeof value === 'number' ? formatPercentage(value) : value),
        },
      },
    },
  },
};

export type EfficiencyChartProps = BoxProps & DataSetParams;

export function EfficiencyChart(props: EfficiencyChartProps): JSX.Element {
  const { ...boxProps } = props;
  const { palette } = useTheme();
  const {
    data,
    params: { plant, cell, workcenter, startDate, endDate },
  } = useDataSet();
  const history = useReadEfficiencyHistoryQuery(
    createDataSetRequestParams({ startDate, endDate, plant, cell, workcenter })
  );
  const target = data?.value.efficiency.target ?? 0;

  const mainDataSetColor = useMemo(() => {
    let color = palette.error.main;
    if (history.data?.length) {
      const lastValue = history.data[history.data.length - 1].efficiency;
      if (lastValue >= target) {
        color = palette.success.main;
      } else if (lastValue >= target * 0.95) {
        color = palette.warning.main;
      }
    }
    return color;
  }, [palette.error.main, palette.success.main, palette.warning.main, history.data, target]);

  const config = useMemo<ChartConfiguration<'line'>>(
    () =>
      history.data
        ? {
            ...chartConfiguration,
            data: {
              ...chartConfiguration.data,
              labels: history.data.map(({ date }) => date),
              datasets: [
                {
                  ...chartConfiguration.data.datasets[0],
                  data: history.data.map(({ efficiency }) => efficiency),
                  borderColor: mainDataSetColor,
                  backgroundColor: alpha(mainDataSetColor, 0.2),
                },
                {
                  ...chartConfiguration.data.datasets[1],
                  data: history.data.map(() => target),
                },
              ],
            },
            options: {
              ...chartConfiguration.options,
              plugins: {
                ...chartConfiguration.options?.plugins,
                title: {
                  ...chartConfiguration.options?.plugins?.title,
                  text: `${chartConfiguration.options?.plugins?.title?.text}${
                    workcenter ? ' workcenter' : cell ? ' cell' : ''
                  } ${data?.name}${
                    startDate && endDate ? ` ${formatDate(startDate, 'P')}-${formatDate(endDate, 'P')}` : ''
                  }`,
                },
              },
            },
          }
        : chartConfiguration,
    [cell, data?.name, endDate, history.data, mainDataSetColor, startDate, target, workcenter]
  );

  return <Chart config={config} loading={history.isFetching} error={history.error} {...boxProps} />;
}
