import { AppUserListGrid, Breadcrumb } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';

const breadcrumbs: Breadcrumb[] = [{ title: 'Users management' }];

export function UsersPage(): JSX.Element {
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRole.ADMIN}>
      <Layout maxWidth={false} breadcrumbs={breadcrumbs} disableGutters sx={{ p: 1 }}>
        <AppUserListGrid appId={import.meta.env.VITE_APP_ID as string} />
      </Layout>
    </AuthGuard>
  );
}
