import { ReactNode, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDataSet } from '../../hooks/useDataSet';
import { useProductivityDetails } from '../../hooks/useProductivityDetails';
import { formatDate } from '../../utils/date';
import { formatNumber } from '../../utils/valueHelpers';

type ProductivityTableRow = {
  label: ReactNode;
  value: number;
};

export function ProductivityTable(): JSX.Element {
  const dataSet = useDataSet();
  const productivity = useProductivityDetails();

  const indirectHours = useMemo(
    () =>
      productivity.data?.filter((group) => group.id !== 'N/A').reduce((hours, group) => hours + group.hours, 0) ?? 0,
    [productivity.data]
  );

  const specialHoursDept = useMemo(
    () =>
      productivity.data
        ?.filter((group) => group.id === 'INDIRETTE')
        .reduce((hours, group) => hours + group.special_total_hours, 0) ?? 0,
    [productivity.data]
  );

  const rows: ProductivityTableRow[] = useMemo(
    () =>
      dataSet.data?.value.uora
        ? [
            {
              label: (
                <>
                  <span className="forumlae">PH</span> (Presence Hours)
                </>
              ),
              value: dataSet.data.value.uora.ph,
            },
            { label: 'PrH (Productive Hours)', value: dataSet.data.value.uora.prh },
            { label: 'Employee PrH', value: dataSet.data.value.uora.prh_employee },
            { label: 'Machine PrH', value: dataSet.data.value.uora.prh_machine },
            { label: 'I (Indirect/Inactive/Training hours)', value: indirectHours - specialHoursDept },
            {
              label: 'Gap: Productive Employee hours + Indirect hours – Presence hours',
              value:
                dataSet.data.value.uora.prh_employee + indirectHours - specialHoursDept - dataSet.data.value.uora.ph,
            },
          ]
        : [],
    [dataSet.data, indirectHours, specialHoursDept]
  );

  return (
    <TableContainer>
      <Table
        sx={{
          '.value': {
            textAlign: 'right',
            '&.error': { color: 'error.main' },
          },
        }}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              Actuals @ {productivity.params.endDate && formatDate(productivity.params.endDate, 'P')}
            </TableCell>
            <TableCell align="right">Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.label}</TableCell>
              <TableCell className={`value ${row.value < 0 ? 'error' : ''}`}>{formatNumber(row.value, 2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
