import { z } from 'zod';
import { EfficiencyDataSetSchema } from './DataSet';
import { ItemDataSchema } from './ItemData';

export const OverviewSchema = ItemDataSchema.extend({
  lastUpdate: z.string().optional(),
  lastADPUpdate: z.string().optional(),
  plants: ItemDataSchema.extend({
    cells: ItemDataSchema.extend({
      type: z.string(),
      workcenters: ItemDataSchema.omit({ value: true })
        .extend({
          value: z.object({
            efficiency: EfficiencyDataSetSchema,
            efficiency_a: EfficiencyDataSetSchema,
            efficiency_co: EfficiencyDataSetSchema,
          }),
        })
        .array(),
    }).array(),
  }).array(),
});

export type Overview = z.infer<typeof OverviewSchema>;
