import { useState } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import { FileDownloadIcon } from '../../components/Icons';

type DownloadReportButtonProps = LoadingButtonProps & {
  year: number;
};

export function DownloadReportButton(props: DownloadReportButtonProps): JSX.Element {
  const { year, ...buttonProps } = props;
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [error, setError] = useState(' ');
  const { token } = useAuth();

  async function download() {
    if (token) {
      setDownloadInProgress(true);
      setError(' ');
      const response = await fetch(`${import.meta.env.VITE_API_BASE_URL ?? ''}/api/v2/trend/${year}/trends`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/vnd.ms-excel',
        },
      });
      if (response.status >= 400) {
        setError(`Error: ${response.status} ${response.statusText}`);
      } else {
        const data: Blob = await response.blob();
        const a = document.createElement('a');
        a.href = URL.createObjectURL(data);
        a.download = `Efficiency Report ${year}.xlsx`;
        a.click();
      }
      setDownloadInProgress(false);
    }
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={download}
        loadingPosition="start"
        loading={downloadInProgress}
        startIcon={<FileDownloadIcon />}
        fullWidth
        {...buttonProps}
      >
        Download
      </LoadingButton>
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </>
  );
}
