import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ReportItem } from '../../entities/Report';
import { formatPercentage, targetStatus } from '../../utils/valueHelpers';

type ReportValueCellProps = {
  data: ReportItem;
  index: number;
};

export const MONTHS_COUNT = 12;
export const CELLS_FOR_MONTH = 3;

function ReportValueCell({ data, index }: ReportValueCellProps): JSX.Element {
  const dataSet = data.data[Math.floor(index / CELLS_FOR_MONTH)];
  const value =
    index % CELLS_FOR_MONTH === 0
      ? dataSet?.efficiency
      : index % CELLS_FOR_MONTH === 1
      ? dataSet?.utilizationOfResource
      : dataSet?.productivity;
  const target =
    index % CELLS_FOR_MONTH === 0
      ? data.target.efficiency
      : index % CELLS_FOR_MONTH === 1
      ? data.target.utilizationOfResource
      : data.target.productivity;

  return (
    <TableCell
      key={index}
      align="right"
      className={
        typeof value === 'number' && typeof target === 'number' ? `target ${targetStatus(value, target)}` : undefined
      }
    >
      {typeof value === 'number' ? formatPercentage(value) : undefined}
    </TableCell>
  );
}

export type ReportRowParams = {
  data: ReportItem;
  depth: number;
};

export function ReportRow({ data, depth }: ReportRowParams): JSX.Element {
  return (
    <TableRow>
      <TableCell component="th" sx={{ fontWeight: 600 - depth * 100 }}>
        {depth === 1 ? <>&nbsp;&nbsp;</> : depth === 2 ? <>&nbsp;&nbsp;&nbsp;&nbsp;</> : null}
        {data.name}
      </TableCell>
      <TableCell align="right" className="target">
        {typeof data.target.efficiency === 'number' && formatPercentage(data.target.efficiency)}
      </TableCell>
      <TableCell align="right" className="target">
        {typeof data.target.utilizationOfResource === 'number' && formatPercentage(data.target.utilizationOfResource)}
      </TableCell>
      <TableCell align="right" className="target">
        {typeof data.target.productivity === 'number' && formatPercentage(data.target.productivity)}
      </TableCell>
      {[...new Array(MONTHS_COUNT * CELLS_FOR_MONTH)].map((_, index) => (
        <ReportValueCell key={index} index={index} data={data} />
      ))}
    </TableRow>
  );
}
