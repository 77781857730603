import { useMemo, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { ServerErrorBox, Breadcrumb } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import Container, { ContainerProps } from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

import { FullPageProgress } from '../Progress';
import { Header } from './Header';

interface AppLayoutProps extends ContainerProps {
  children?: ReactNode | ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: ReactNode;
  headerFilters?: ReactNode;
}

const APP_NAME = import.meta.env.VITE_NAME as string;

export default function Layout(props: AppLayoutProps): JSX.Element {
  const {
    children,
    title,
    breadcrumbs,
    headerFilters,
    inProgress,
    error,
    progressIndicator,
    maxWidth,
    sx,
    ...containerProps
  } = props;

  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header breadcrumbs={breadcrumbs}>{headerFilters}</Header>
      <Toolbar />
      <Container
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          py: 4,
          ...sx,
        }}
        maxWidth={error ? 'sm' : maxWidth}
        {...containerProps}
      >
        {inProgress ? progressIndicator || <FullPageProgress /> : error ? <ServerErrorBox error={error} /> : children}
      </Container>
    </>
  );
}
