import { useMemo } from 'react';
import { Breadcrumb, DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import {
  GridColDef,
  GridActionsColDef,
  GridRenderCellParams,
  GridGroupingColDefOverride,
} from '@mui/x-data-grid-premium';
import { DateRangeFilters } from '../../components/HeaderFilters';
import { Layout } from '../../components/Layout';
import { DataSet } from '../../entities/DataSet';
import { useOverview } from '../../hooks/useOverview';
import { formatPercentage } from '../../utils/valueHelpers';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { DataSetCell } from './DataSetCell';
import { OverviewRow } from './OverviewRow';

const breadcrumbs: Breadcrumb[] = [];

const groupingColDef: GridGroupingColDefOverride = { width: 400 };

const columns: (GridColDef | GridActionsColDef)[] = [
  { field: 'cdc', headerName: 'CdC', width: 120 },
  {
    field: 'eff',
    headerName: 'Efficiency E_r',
    align: 'center',
    sortable: false,
    renderCell: ({ row, value }: GridRenderCellParams<DataSet, OverviewRow>) =>
      value === undefined ? (
        'N/A'
      ) : (
        <DataSetCell
          row={row}
          value={value}
          to="/details/efficiency"
          dot={row.eff_a ? Math.abs(value.value - row.eff_a.value) > 0.015 : undefined}
        />
      ),
    width: 140,
  },
  {
    field: 'eff_a',
    headerName: 'Efficiency E_a',
    align: 'right',
    sortable: false,
    valueFormatter: (params) => params.value && formatPercentage(params.value.value),
    renderCell: ({ row, value }: GridRenderCellParams<DataSet, OverviewRow>) =>
      value === undefined ? 'N/A' : <DataSetCell row={row} value={value} to="/details/efficiency" />,
    width: 140,
  },
  {
    field: 'eff_co',
    headerName: 'Efficiency E_c (closed wo)',
    align: 'right',
    sortable: false,
    valueFormatter: (params) => params.value && formatPercentage(params.value.value),
    renderCell: ({ row, value }: GridRenderCellParams<DataSet, OverviewRow>) =>
      value === undefined ? 'N/A' : <DataSetCell row={row} value={value} to="/details/efficiency" />,
    width: 140,
  },
  {
    field: 'uor_a',
    headerName: 'UoR_a',
    align: 'right',
    sortable: false,
    valueFormatter: (params) => params.value && formatPercentage(params.value.value),
    renderCell: ({ row, value }: GridRenderCellParams<DataSet, OverviewRow>) =>
      value === undefined ? 'N/A' : <DataSetCell row={row} value={value} to="/details/efficiency" />,
    width: 140,
  },
  {
    field: 'prod',
    headerName: 'Productivity P_r',
    align: 'right',
    sortable: false,
    valueFormatter: (params) => params.value && formatPercentage(params.value.value),
    renderCell: ({ row, value }: GridRenderCellParams<DataSet, OverviewRow>) =>
      value === undefined ? 'N/A' : <DataSetCell row={row} value={value} to="/details/productivity" />,
    width: 140,
  },
];

export function OverviewPage(): JSX.Element {
  const { data, error, isFetching } = useOverview();

  const rows = useMemo<OverviewRow[]>(() => {
    if (!data) {
      return [];
    }
    const rows: OverviewRow[] = [
      {
        id: 'Italy',
        eff: data.value.efficiency.dlh !== 0 ? data.value.efficiency : null,
        uor_a: data.value.uora && (data.value.uora.ph !== 0 ? data.value.uora : null),
        prod: data.value.productivity && (data.value.productivity.uora !== 0 ? data.value.productivity : null),
        eff_a: data.value.efficiency_a.dlh ? data.value.efficiency_a : null,
        eff_co: data.value.efficiency_co.dlh ? data.value.efficiency_co : null,
      },
    ];
    data.plants.forEach((plant) => {
      rows.push({
        id: `Italy:${plant.name}`,
        plant: plant.name,
        eff: plant.value.efficiency.dlh !== 0 ? plant.value.efficiency : null,
        uor_a: plant.value.uora && (plant.value.uora.ph !== 0 ? plant.value.uora : null),
        prod: plant.value.productivity && (plant.value.productivity.uora !== 0 ? plant.value.productivity : null),
        eff_a: plant.value.efficiency_a.dlh ? plant.value.efficiency_a : null,
        eff_co: plant.value.efficiency_co.dlh ? plant.value.efficiency_co : null,
      });
      plant.cells.forEach((cell) => {
        rows.push({
          id: `Italy:${plant.name}:${cell.name}`,
          plant: plant.name,
          cell: cell.name,
          eff: cell.value.efficiency.dlh !== 0 ? cell.value.efficiency : null,
          uor_a: cell.value.uora && (cell.value.uora.ph !== 0 ? cell.value.uora : null),
          prod: cell.value.productivity && (cell.value.productivity.uora !== 0 ? cell.value.productivity : null),
          eff_a: cell.value.efficiency_a.dlh ? cell.value.efficiency_a : null,
          eff_co: cell.value.efficiency_co.dlh ? cell.value.efficiency_co : null,
        });
        cell.workcenters.forEach((workcenter) => {
          rows.push({
            id: `Italy:${plant.name}:${cell.name}:${workcenter.name}`,
            plant: plant.name,
            cell: cell.name,
            cdc: workcenter.cdc,
            workcenter: workcenter.name,
            eff: workcenter.value.efficiency.dlh !== 0 ? workcenter.value.efficiency : null,
            eff_a: workcenter.value.efficiency_a.dlh ? workcenter.value.efficiency_a : null,
            eff_co: workcenter.value.efficiency_co.dlh ? workcenter.value.efficiency_co : null,
          });
        });
      });
    });
    return rows;
  }, [data]);

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        disableGutters
        sx={{ p: 1 }}
        headerFilters={<DateRangeFilters />}
        error={error}
        inProgress={isFetching}
      >
        <DataGridWrapper>
          <DataGrid
            treeData
            columns={columns}
            rows={rows}
            getTreeDataPath={(row) => row.id.split(':')}
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={2}
            density="compact"
            disableColumnMenu
            hideFooter
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}
