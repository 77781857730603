import { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { useOverview } from '../../hooks/useOverview';
import { useSearchParams } from '../../hooks/useSearchParams';

export function DataSetFilters(): JSX.Element {
  const overview = useOverview();
  const [{ startDate, endDate, plant, cell, workcenter }, setSearchParams] = useSearchParams();

  const plantList = useMemo<string[]>(
    () => overview?.data?.plants.map(({ name }) => name) ?? [],
    [overview?.data?.plants]
  );

  const cellList = useMemo<string[]>(
    () => overview?.data?.plants.find(({ name }) => name === plant)?.cells.map(({ name }) => name) ?? [],
    [overview?.data?.plants, plant]
  );

  const workcenterList = useMemo<string[]>(
    () =>
      overview?.data?.plants
        .find(({ name }) => name === plant)
        ?.cells.find(({ name }) => name === cell)
        ?.workcenters.map(({ name }) => name) ?? [],
    [overview?.data?.plants, plant, cell]
  );

  return overview.isFetching ? (
    <>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
    </>
  ) : (
    <>
      <TextField
        label="Plant"
        size="small"
        value={plant ?? ''}
        onChange={(event) =>
          setSearchParams({
            plant: event.target.value,
            startDate,
            endDate,
          })
        }
        disabled={!overview.data}
        select
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {plantList.map((plant) => (
          <MenuItem key={plant} value={plant}>
            {plant}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Cell"
        size="small"
        value={cell ?? ''}
        onChange={(event) =>
          setSearchParams({
            plant,
            cell: event.target.value,
            startDate,
            endDate,
          })
        }
        disabled={!cellList?.length}
        select
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {cellList.map((cell) => (
          <MenuItem key={cell} value={cell}>
            {cell}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Workcenter"
        size="small"
        value={workcenter ?? ''}
        onChange={(event) =>
          setSearchParams({
            plant,
            cell,
            workcenter: event.target.value,
            startDate,
            endDate,
          })
        }
        disabled={!workcenterList?.length}
        select
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {workcenterList.map((workcenter) => (
          <MenuItem key={workcenter} value={workcenter}>
            {workcenter}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
