import { useCallback, useEffect, useRef } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import { Chart as ChartJS, ChartConfiguration } from 'chart.js';
import { ZodError } from 'zod';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FileDownloadIcon } from './Icons';
import { FullPageProgress } from './Progress';

export type ChartProps = BoxProps & {
  config: ChartConfiguration;
  loading?: boolean;
  error?: Error | ApiError | ZodError;
};

export function Chart(props: ChartProps): JSX.Element {
  const { config, loading, error, ...boxProps } = props;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartRef = useRef<ChartJS | null>(null);

  const download = useCallback(() => {
    if (canvasRef.current) {
      const a = document.createElement('a');
      a.href = canvasRef.current.toDataURL();
      a.download = `${config.options?.plugins?.title ?? 'chart'}.png`;
      a.click();
    }
  }, [config.options?.plugins?.title]);

  useEffect(() => {
    let chart = chartRef.current;
    if (canvasRef.current && !chart) {
      chart = new ChartJS(canvasRef.current, config);
      chartRef.current = chart;
    }
    return () => {
      if (chart) {
        chartRef.current = null;
        chart.destroy();
      }
    };
  }, [config]);

  if (loading) {
    return <FullPageProgress {...boxProps} />;
  }

  if (error) {
    return <ErrorAlert error={error} sx={boxProps.sx} />;
  }

  return (
    <Box position="relative" {...boxProps}>
      <Tooltip title="Download chart" sx={{ position: 'absolute', top: 40, right: 8 }}>
        <IconButton onClick={download}>
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <canvas ref={canvasRef} />
    </Box>
  );
}
