import { format, formatISO, parseISO, isValid } from 'date-fns';
import it from 'date-fns/locale/it';

export function formatDate(date: Date, fmrt: string): string {
  return format(date, fmrt, { locale: it });
}

export function formatISODate(date: Date): string {
  return formatISO(date, { representation: 'date' });
}

export function parseISODate(value: unknown): Date | null {
  if (typeof value === 'string') {
    const date = parseISO(value);
    if (isValid(date)) {
      return date;
    }
  }
  return null;
}
