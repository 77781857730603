import { z } from 'zod';
import { EfficiencyDataSetSchema, ProductivityDataSetSchema, UtilizationOfResourceDataSetSchema } from './DataSet';

export const ItemDataSchema = z.object({
  name: z.string(),
  chief: z.string().optional(),
  manager: z.string().optional(),
  cdc: z.string().optional(),
  value: z.object({
    efficiency: EfficiencyDataSetSchema,
    efficiency_a: EfficiencyDataSetSchema,
    efficiency_co: EfficiencyDataSetSchema,
    productivity: ProductivityDataSetSchema.optional(),
    uora: UtilizationOfResourceDataSetSchema.optional(),
  }),
});

export type ItemData = z.infer<typeof ItemDataSchema>;
