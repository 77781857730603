import { useMemo } from 'react';
import { createDataSetRequestParams, DataSetRequestParams } from '../entities/DataSet';
import { Overview } from '../entities/Overview';
import { useReadOverviewQuery } from '../services/overviewApi';
import { useSearchParams } from './useSearchParams';

type UseOverview = Omit<ReturnType<typeof useReadOverviewQuery>, 'data'> & {
  data?: Overview;
  params: {
    startDate?: Date;
    endDate?: Date;
  };
};

export function useOverview(): UseOverview {
  const [{ startDate, endDate }] = useSearchParams();
  const readOverviewParams = useMemo(() => {
    if (startDate && endDate) {
      return createDataSetRequestParams({ startDate, endDate });
    }
  }, [endDate, startDate]);

  const overview = useReadOverviewQuery(readOverviewParams as DataSetRequestParams, { skip: !readOverviewParams });
  return {
    ...overview,
    params: { startDate, endDate },
  };
}
