import { useState } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import { FileDownloadIcon } from '../../components/Icons';
import { DataSetParams } from '../../entities/DataSet';
import { formatISODate } from '../../utils/date';

type DownloadDetailsButtonProps = LoadingButtonProps & {
  baseUrl: string;
  fileNamePrefix: string;
  params: DataSetParams;
};

export function DownloadDetailsButton(props: DownloadDetailsButtonProps): JSX.Element {
  const { params, baseUrl, fileNamePrefix, children, ...buttonProps } = props;
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [error, setError] = useState(' ');
  const { token } = useAuth();

  async function download() {
    if (token) {
      setDownloadInProgress(true);
      setError(' ');
      const query = new URLSearchParams();
      if (params.plant) {
        query.set('plant', params.plant);
      }
      if (params.cell) {
        query.set('cell', params.cell);
      }
      if (params.workcenter) {
        query.set('workcenter', params.workcenter);
      }
      if (params.startDate) {
        query.set('startDate', formatISODate(params.startDate));
      }
      if (params.endDate) {
        query.set('endDate', formatISODate(params.endDate));
      }

      const response = await fetch(`${import.meta.env.VITE_API_BASE_URL ?? ''}/${baseUrl}?${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/vnd.ms-excel',
        },
      });
      if (response.status >= 400) {
        setError(`Error: ${response.status} ${response.statusText}`);
      } else {
        const data: Blob = await response.blob();
        const a = document.createElement('a');
        a.href = URL.createObjectURL(data);
        a.download = `${fileNamePrefix}${params.plant ? ` ${params.plant}` : ''}${
          params.cell ? ` ${params.cell}` : ''
        }${params.workcenter ? ` ${params.workcenter}` : ''}${
          params.startDate ? ` ${formatISODate(params.startDate)}` : ''
        }${params.endDate ? ` ${formatISODate(params.endDate)}` : ''}.xlsx`;
        a.click();
      }
      setDownloadInProgress(false);
    }
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        color="secondary"
        size="small"
        onClick={download}
        loadingPosition="start"
        loading={downloadInProgress}
        startIcon={<FileDownloadIcon />}
        fullWidth
        {...buttonProps}
      >
        {children}
      </LoadingButton>
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </>
  );
}
