import { z } from 'zod';

export const ParserRunSchema = z.object({
  description: z.string(),
  error: z.string().optional(),
  lastRun: z.string(),
  lastSuccess: z.string().optional(),
  path: z.string(),
  status: z.string(),
});

export type ParserRun = z.infer<typeof ParserRunSchema>;
