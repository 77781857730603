import { useMemo } from 'react';
import { DataSetParams } from '../entities/DataSet';
import { ItemData } from '../entities/ItemData';
import { parseISODate } from '../utils/date';
import { useOverview } from './useOverview';
import { useSearchParams } from './useSearchParams';

type UseDataSet = Omit<ReturnType<typeof useOverview>, 'data' | 'params'> & {
  data?: ItemData;
  lastUpdate: Date | null;
  lastADPUpdate: Date | null;
  params: DataSetParams;
};

export function useDataSet(): UseDataSet {
  const [{ plant, cell, workcenter }] = useSearchParams();
  const overview = useOverview();

  const data = useMemo<ItemData | undefined>(() => {
    let values: ItemData | undefined = overview.data;
    let plantData;
    let cellData;
    let workcenterData;
    if (plant && overview.data?.plants) {
      plantData = overview.data?.plants.find(({ name }) => name === plant);
      values = plantData;
      if (cell && plantData?.cells) {
        cellData = plantData.cells.find(({ name }) => name === cell);
        values = cellData;
        if (workcenter && cellData?.workcenters) {
          workcenterData = cellData.workcenters.find(({ name }) => name === workcenter);
          values = workcenterData;
        }
      }
    }
    return values;
  }, [overview.data, plant, cell, workcenter]);

  return {
    ...overview,
    data,
    lastUpdate: parseISODate(overview.data?.lastUpdate),
    lastADPUpdate: parseISODate(overview.data?.lastADPUpdate),
    params: {
      ...overview.params,
      plant,
      cell,
      workcenter,
    },
  };
}
