import { useMemo } from 'react';
import { isBefore } from 'date-fns';
import { createDataSetRequestParams, DataSetParams } from '../entities/DataSet';
import { ProductivityDetailsGroup } from '../entities/ProductivityDetails';
import { useSearchParams } from '../hooks/useSearchParams';
import { useReadProductivityDetailsQuery } from '../services/productivityApi';
import { useAvailableDates } from './useAvailableDates';

type useProductivityDetails = Omit<ReturnType<typeof useReadProductivityDetailsQuery>, 'data'> & {
  data?: ProductivityDetailsGroup[];
  params: DataSetParams;
};

export function useProductivityDetails() {
  const [params] = useSearchParams();
  const { data, isFetching, error } = useReadProductivityDetailsQuery(createDataSetRequestParams(params));
  const availableDates = useAvailableDates();

  const endDate = useMemo(() => {
    const endDate = params.endDate;
    const lastADP = availableDates.lastAvailableADPDate;
    if (lastADP && endDate && isBefore(lastADP, endDate)) {
      return lastADP ?? undefined;
    }
    return endDate;
  }, [availableDates.lastAvailableADPDate, params.endDate]);

  return {
    isFetching,
    error,
    data,
    params: {
      ...params,
      endDate,
    },
  };
}
