import { z } from 'zod';

export const ProductivityDetailsItemSchema = z.object({
  hours: z.number(),
  id: z.string(),
  name: z.string(),
});

export type ProductivityDetailsItem = z.infer<typeof ProductivityDetailsItemSchema>;

export const ProductivityDetailsGroupSchema = z.object({
  hours: z.number(),
  id: z.string(),
  items: ProductivityDetailsItemSchema.array(),
  name: z.string(),
  special_total_hours: z.number(),
});

export type ProductivityDetailsGroup = z.infer<typeof ProductivityDetailsGroupSchema>;
