import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { DataSet } from '../../entities/DataSet';
import { useSearchParams } from '../../hooks/useSearchParams';
import { formatISODate } from '../../utils/date';
import { formatPercentage, dataSetStatus } from '../../utils/valueHelpers';
import { OverviewRow } from './OverviewRow';

export type DataSetCellParams = {
  row: OverviewRow;
  value: DataSet | null;
  to: string;
  dot?: boolean;
};

const Dot = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  display: 'inline-block',
  height: 10,
  borderRadius: '100%',
  backgroundColor: theme.palette.error.main,
  flex: '0 0 10px',
}));

export function DataSetCell(props: DataSetCellParams): JSX.Element {
  const { row, value, to, dot } = props;
  const [{ startDate, endDate }] = useSearchParams();
  const query = useMemo(() => {
    const query = new URLSearchParams();
    if (startDate) {
      query.set('startDate', formatISODate(startDate));
    }
    if (endDate) {
      query.set('endDate', formatISODate(endDate));
    }
    if (row.plant) {
      query.set('plant', row.plant);
    }
    if (row.cell) {
      query.set('cell', row.cell);
    }
    if (row.workcenter) {
      query.set('workcenter', row.workcenter);
    }
    return query;
  }, [endDate, row.cell, row.plant, row.workcenter, startDate]);

  return (
    <>
      <Button
        component={Link}
        to={`${to}/?${query}`}
        variant="contained"
        color={value ? dataSetStatus(value) : 'secondary'}
        fullWidth
        size="small"
      >
        {value ? formatPercentage(value.value) : '–'}
        &nbsp;
        {value && (
          <Box sx={{ color: 'text.primary', fontWeight: 'regular', fontSize: '.85em' }}>
            ({formatPercentage(value.target)})
          </Box>
        )}
      </Button>
      <Dot sx={{ visibility: dot ? 'visible' : 'hidden' }} />
    </>
  );
}
