import { NumberUtils } from '@top-solution/microtecnica-utils';
import { DataSet } from '../entities/DataSet';

export const numberUtils = new NumberUtils({});

export function formatPercentage(value: number, decimals = 1): string {
  return numberUtils.format(value, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    style: 'percent',
  });
}

export function formatNumber(value: number, decimals = 1): string {
  return numberUtils.format(value, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export type Status = 'success' | 'warning' | 'error';

export function targetStatus(value: number, target: number): Status {
  if (value >= target) {
    return 'success';
  }
  if (value >= target * 0.95) {
    return 'warning';
  }
  return 'error';
}

export function dataSetStatus(dataSet: DataSet): Status {
  return targetStatus(dataSet.value, dataSet.target);
}
