import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import deepPurple from '@mui/material/colors/deepPurple';
import grey from '@mui/material/colors/grey';
import lime from '@mui/material/colors/lime';
import { itIT as coreLocale } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { itIT as dataGridLocale } from '@mui/x-data-grid-pro';
import { enUS as datePickersLocale } from '@mui/x-date-pickers-pro';

const baseThemeConfig: ThemeOptions = {
  palette: {
    primary: deepPurple,
    secondary: blueGrey,
    background: {
      default: grey[50],
    },
  },
};

export const theme = createTheme(baseThemeConfig, datePickersLocale, dataGridLocale, coreLocale);

const darkThemeConfig: ThemeOptions = {
  palette: {
    primary: lime,
    secondary: blue,
    mode: 'dark',
  },
};

export const darkTheme = createTheme(darkThemeConfig, datePickersLocale, dataGridLocale, coreLocale);
