import Box from '@mui/material/Box';
import { ProductivityChart } from './ProductivityChart';
import { ProductivityDataGrid } from './ProductivityDataGrid';
import { ProductivityTable } from './ProductivityTable';

export function ProductivityDetails(): JSX.Element {
  return (
    <Box
      sx={{
        '.MuiDataGrid-root': {
          borderRadius: 0,
          marginY: 4,
          marginX: '-1px',
        },
      }}
    >
      <ProductivityTable />
      <ProductivityDataGrid />
      <ProductivityChart height={800} sx={{ marginX: 1, marginY: 4 }} />
    </Box>
  );
}
