import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { GaugeChart } from '../../components/GaugeChart';
import { useDataSet } from '../../hooks/useDataSet';
import { formatDate } from '../../utils/date';

type DetailsHeaderProps = PaperProps;

export function DetailsHeader(props: DetailsHeaderProps) {
  const { sx, ...paperProps } = props;
  const {
    data,
    params: { plant, cell, workcenter, startDate, endDate },
  } = useDataSet();
  const gaugeMax = useMemo(
    () =>
      data
        ? Math.ceil(
            Math.max(data.value.efficiency.value, data.value.uora?.value ?? 0, data.value.productivity?.value ?? 0) /
              0.5
          ) * 0.5
        : 1,
    [data]
  );
  if (!data) {
    return null;
  }
  return (
    <Paper sx={{ padding: 2, ...sx }} {...paperProps}>
      <Box sx={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'baseline' }}>
        <Typography component="h1" variant="h4">
          {plant ?? data.name}
        </Typography>
        <Box sx={{ flex: '1 0 2ch' }} />
        {startDate && endDate && (
          <Typography component="h1" variant="h6">
            {formatDate(startDate, 'P')} – {formatDate(endDate, 'P')}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'baseline', marginY: 1 }}>
        {cell && (
          <Typography component="h1" variant="h5">
            {cell}
            {workcenter ? ` – ${workcenter}` : ''}
          </Typography>
        )}
        <Box sx={{ flex: '1 0 2ch' }} />
        <Typography component="h3" variant="h6" sx={{ 'span+span::before': { content: '" – "' } }}>
          {data.chief && <span>Chief: {data.chief}</span>}
          {data.manager && <span>Manager: {data.manager}</span>}
        </Typography>
      </Box>
      {data.value && (
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', marginTop: 2 }}>
          {data.value.efficiency.dlh !== 0 && (
            <GaugeChart title="Efficiency" value={data.value.efficiency} max={gaugeMax} />
          )}
          {data.value.uora && data.value.uora.ph !== 0 && (
            <GaugeChart title="UoR_a" value={data.value.uora} max={gaugeMax} />
          )}
          {data.value.productivity && data.value.productivity.uora !== 0 && (
            <GaugeChart title="Productivity_OE" value={data.value.productivity} max={gaugeMax} />
          )}
        </Box>
      )}
    </Paper>
  );
}
