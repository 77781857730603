import { Report, ReportSchema } from '../entities/Report';
import { api } from './baseApi';

const url = 'v2/trend';

const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readReport: builder.query<Report, string>({
      query: (year) => ({ url: `${url}/${year}` }),
      transformResponse: (response) => ReportSchema.parse(response),
    }),
  }),
  overrideExisting: false,
});

export const { useReadReportQuery } = reportApi;
