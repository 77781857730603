import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Header as MuiHeader, HeaderProps, MenuItem } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useSearchParams } from '../../hooks/useSearchParams';
import { darkTheme } from '../../theme';
import { formatISODate } from '../../utils/date';
import { ChartAreasplineIcon, ListStatusIcon, ManageUsersIcon } from '../Icons';

export function Header(props: HeaderProps): JSX.Element {
  const theme = useTheme();
  const { breadcrumbs, children } = props;
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const [{ startDate, endDate }] = useSearchParams();
  const query = useMemo(() => {
    const query = new URLSearchParams();
    if (startDate) {
      query.set('startDate', formatISODate(startDate));
    }
    if (endDate) {
      query.set('endDate', formatISODate(endDate));
    }
    return query;
  }, [endDate, startDate]);

  const menuItems = useMemo<MenuItem[] | undefined>(() => {
    const menuItems: MenuItem[] = [
      {
        label: 'Report',
        icon: <ChartAreasplineIcon />,
        onClick: (closeMenu) => {
          navigate('/report');
          closeMenu();
        },
      },
    ];
    if (isAdmin) {
      menuItems.push({
        label: 'Parser status',
        icon: <ListStatusIcon />,
        onClick: (closeMenu) => {
          navigate('/debug');
          closeMenu();
        },
      });
      menuItems.push({
        label: 'Users management',
        icon: <ManageUsersIcon />,
        onClick: (closeMenu) => {
          navigate('/users');
          closeMenu();
        },
      });
    }
    return menuItems;
  }, [isAdmin, navigate]);

  return (
    <MuiHeader
      logo={{ to: `/?${query}` }}
      position="fixed"
      breadcrumbs={breadcrumbs}
      smallScreenBreakpoint={theme.breakpoints.values.sm}
      menuItems={menuItems}
    >
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            marginX: 1,
            display: 'flex',
            '.MuiFormControl-root, .MuiSkeleton-root': {
              marginRight: 0.5,
              minWidth: 140,
            },
            '.MuiSkeleton-root': {
              borderRadius: 1,
              height: 40,
            },
            '.DatePicker-root': {
              width: 140,
            },
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </MuiHeader>
  );
}
