import { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { endOfYear, startOfYear } from 'date-fns';
import en from 'date-fns/locale/en-US';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { Layout } from '../../components/Layout';
import { useAvailableDates } from '../../hooks/useAvailableDates';
import { useReadReportQuery } from '../../services/reportApi';
import { parseISODate } from '../../utils/date';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { DownloadReportButton } from './DownloadReportButton';
import { ReportRow, MONTHS_COUNT, CELLS_FOR_MONTH } from './ReportRow';

const months: string[] = [...new Array(MONTHS_COUNT)].map((_, index) => `${en.localize?.month(index)}`);

export function ReportPage(): JSX.Element {
  const [params, setParams] = useSearchParams();
  const availableDates = useAvailableDates();

  const minDate = availableDates.firstAvailableDate ? startOfYear(availableDates.firstAvailableDate) : undefined;
  const maxDate = availableDates.lastAvailableDate ? endOfYear(availableDates.lastAvailableDate) : undefined;

  const parsedDate = parseISODate(`${params.get('year')}-01-01`);
  const date = parsedDate ? endOfYear(parsedDate) : maxDate;
  const year = date?.getFullYear();

  const report = useReadReportQuery(`${year}`, {
    skip: !date || !maxDate || !minDate || date > maxDate || date < minDate,
  });

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout
        title={`Report ${year ?? ''}`}
        disableGutters
        maxWidth={report.error ? 'sm' : false}
        sx={{ p: 1 }}
        inProgress={report.isFetching}
        error={report.error}
        headerFilters={
          availableDates.isFetching ? (
            <>
              <Skeleton variant="rectangular" />
            </>
          ) : (
            <DatePicker
              views={['year']}
              label="Start"
              minDate={minDate}
              maxDate={maxDate}
              value={date}
              onChange={(value) => {
                if (value && minDate && maxDate && value >= minDate && value <= maxDate) {
                  setParams(new URLSearchParams({ year: value.getFullYear().toString() }));
                }
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          )
        }
      >
        {report.data && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ '.MuiTableCell-head': { textAlign: 'center' } }}>
                <TableRow>
                  <TableCell rowSpan={2}>
                    {year && <DownloadReportButton year={year}>Download</DownloadReportButton>}
                  </TableCell>
                  <TableCell colSpan={CELLS_FOR_MONTH} className="target">
                    Target
                  </TableCell>
                  {months.map((month) => (
                    <TableCell colSpan={CELLS_FOR_MONTH} key={month}>
                      {month}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {[...new Array((MONTHS_COUNT + 1) * CELLS_FOR_MONTH)].map((_, index) => (
                    <TableCell key={index} className={index < CELLS_FOR_MONTH ? 'target' : undefined}>
                      {index % CELLS_FOR_MONTH === 0 ? 'Eff' : index % CELLS_FOR_MONTH === 1 ? 'UoR' : 'Prod'}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ '.MuiTableCell-body': { padding: 0.5 }, th: { whiteSpace: 'nowrap' } }}>
                <ReportRow data={report.data} depth={0} />
                {report.data.plants.map((plant) => (
                  <Fragment key={plant.name}>
                    <ReportRow data={plant} depth={1} />
                    {plant.cells.map((cell) => (
                      <ReportRow data={cell} depth={2} key={`${plant.name}-${cell.name}`} />
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Layout>
    </AuthGuard>
  );
}
